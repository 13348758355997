export const defaultModel = "gpt-3.5-turbo";

export const availableModels = [
  {
    value: "gpt-3.5-turbo",
    label: "GPT-3.5-TURBO (Default ChatGPT)",
  },
  { value: "gpt-3.5-turbo-0301", label: "GPT-3.5-TURBO-0301" },
  { value: "gpt-4", label: "GPT-4 (Limited Beta)" },
  { value: "gpt-4-0314", label: "GPT-4-0314 (Limited Beta)" },
  { value: "gpt-4-32k", label: "GPT-4-32K (Limited Beta)" },
  {
    value: "gpt-4-32k-0314",
    label: "GPT-4-32K-0314 (Limited Beta)",
  },
];

export const writingCharacters = [
  {
    label: "Standup Comedian",
    value:
      "A performer who entertains audiences by telling jokes and humorous stories.",
  },
  {
    label: "Life Coach",
    value:
      "A professional who helps individuals identify and achieve their personal and professional goals.",
  },
  {
    label: "Career Counselor",
    value:
      "A professional who helps individuals explore and choose careers, develop job search strategies, and improve job performance.",
  },
  {
    label: "Nutritionist",
    value:
      "A health professional who specializes in the study of nutrition and its effects on the body.",
  },
  {
    label: "Product Manager",
    value:
      "A professional who oversees the development and marketing of a company's products.",
  },
  {
    label: "Personal Trainer",
    value:
      "A fitness professional who works with individuals to develop personalized exercise programs and improve their overall health and fitness.",
  },
  {
    label: "Life Hacker",
    value:
      "A person who uses unconventional methods to solve problems and increase productivity in everyday life.",
  },
  {
    label: "Travel Advisor",
    value:
      "A professional who helps individuals plan and book travel arrangements.",
  },
  {
    label: "Mindfulness Coach",
    value:
      "A professional who helps individuals develop mindfulness practices to reduce stress and improve well-being.",
  },
  {
    label: "Financial Advisor",
    value:
      "A professional who provides guidance and advice on financial planning, investment strategies, and retirement planning.",
  },
  {
    label: "Language Tutor",
    value:
      "A teacher who helps individuals learn and improve their language skills.",
  },
  {
    label: "Travel Guide",
    value:
      "A professional who leads tours and provides information about local attractions and culture.",
  },
  {
    label: "Marketing Expert",
    value:
      "A professional who develops and implements marketing strategies to promote products and services.",
  },
  {
    label: "Software Developer",
    value:
      "A professional who designs, develops, and maintains software applications and systems.",
  },
  {
    label: "Dating Coach",
    value:
      "A professional who helps individuals improve their dating and relationship skills.",
  },
  {
    label: "DIY Expert",
    value:
      "A person who is skilled at completing a wide range of do-it-yourself projects around the home.",
  },
  {
    label: "Journalist",
    value:
      "A professional who investigates and reports on current events and news stories.",
  },
  {
    label: "Tech Writer",
    value:
      "A professional who writes about technology and related topics for a variety of audiences.",
  },
  {
    label: "Professional Chef",
    value:
      "A skilled culinary professional who prepares meals and manages kitchen operations.",
  },
  {
    label: "Professional Salesperson",
    value:
      "A professional who sells products and services to businesses and consumers.",
  },
  {
    label: "Startup Tech Lawyer",
    value:
      "A legal professional who specializes in providing legal advice and services to startup technology companies.",
  },
  {
    label: "Graphic Designer",
    value:
      "A professional who designs visual materials such as logos, brochures, and websites.",
  },
  {
    label: "Academic Researcher",
    value:
      "A professional who conducts research and produces scholarly work in a particular academic field.",
  },
  {
    label: "Customer Support Agent",
    value:
      "A professional who provides assistance and support to customers who have questions or problems with a company's products or services.",
  },
  {
    label: "HR Consultant",
    value:
      "A professional who provides guidance and advice to organizations on human resource management and strategy",
  },
];

export const writingTones = [
  "Assertive",
  "Authoritative",
  "Casual",
  "Confident",
  "Condescending",
  "Conversational",
  "Diplomatic",
  "Direct",
  "Eloquent",
  "Formal",
  "Friendly",
  "Humorous",
  "Informative",
  "Inspiring",
  "Intense",
  "Irritable",
  "Joking",
  "Polite",
  "Sarcastic",
  "Sincere",
  "Soothing",
  "Stern",
  "Sympathetic",
  "Tactful",
  "Witty",
];

export const writingStyles = [
  "Academic",
  "Analytical",
  "Argumentative",
  "Conversational",
  "Creative",
  "Critical",
  "Descriptive",
  "Explanatory",
  "Informative",
  "Instructive",
  "Investigative",
  "Journalistic",
  "Metaphorical",
  "Narrative",
  "Persuasive",
  "Poetic",
  "Satirical",
  "Technical",
];

export const writingFormats = [
  { value: "Answer as concise as possible", label: "Concise" },
  { value: "Think step-by-step", label: "Step-by-step" },
  { value: "Answer in painstakingly detail", label: "Extreme Detail" },
  { value: "Explain like I'm five", label: "Explain Like I'm Five" },
];
